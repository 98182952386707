<template>
  <div class="reportPartyMemberInfo">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
      :isShowHeadBtn="false"
      v-bind="submitConfig"
      :isSearch="false"
      :isHead="false"
      :beforeList="beforeList"
      :afterReturnList="afterList"
      isManySelect
      :selectable="selectable"
    >
      <template #searchSlot>
        <v-input
          v-model="submitConfig.postData.queryData.voteName"
          label="名称："
          placeholder="请输入"
        />
        <v-select
          v-model="submitConfig.postData.queryData.tenantId"
          label="社区名称："
          :options="tenantName"
          @change="handelChange"
          placeholder="选择社区"
          @clear="submitConfig.postData.queryData.tenantId = null"
        />
        <v-select
          clearable
          v-model="submitConfig.postData.queryData.reportStatus"
          label="上报状态"
          :options="isSucceedStatusOps"
          placeholder="请选择"
          @change="$refs.list.getList()"
          @clear="submitConfig.postData.queryData.reportStatus = null"
        />
        <v-button text="查询" @click="goSearch"></v-button>
        <v-button
          icon="el-icon-plus"
          text="新建"
          @click="handelAddClick"
        ></v-button>
        <v-button text="导入" @click="goImportData"></v-button>
        <div class="tag">手动上报</div>
      </template>
      <template #operateControl="scope">
        <v-button
          text="编辑"
          :disabled="scope.row.isReport == 1 && scope.row.responseResult != 0"
          class="grayBtn"
          type="text"
          @click="editData(scope.row)"
        />
        <v-button text="查看" type="text" @click="viewData(scope.row)" />
        <v-button text="日志" type="text" @click="goViewLog(scope.row)" />
        <v-button text="删除" type="text" @click="deleteData(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button
          v-loading.fullscreen.lock="isLoading"
          element-loading-text="拼命上报中"
          text="批量上报"
          :disabled="scope.selectedData.data.length === 0"
          @click="reportDataMode(scope.selectedData, 1)"
        />
        <v-button
          :disabled="isShowAllBtn"
          text="全部上报"
          v-loading.fullscreen.lock="isAllLoading"
          element-loading-text="拼命上报中"
          @click="reportDataMode(scope.selectedData, 2)"
        />
      </template>
    </v-list>
    <v-drawer
      :title="title"
      :isShowDrawer="isShowDrawer"
      @changeDrawer="changeDrawer"
    >
      <v-form
        :isEdit="isEdit"
        :isShowReportBtn="isShowReportBtn"
        :dataList="dataList"
        :fields="fieldList"
        @save="save"
        @report="report"
      ></v-form>
    </v-drawer>
  </div>
</template>

<script>
import {
  getFieldTypes,
  getTenantList,
  getComplexList,
  getPageListUrl,
  editDataUrl,
  getDataInfo,
  getAddData,
  getPushDataUrl,
  deleteDataUrl,
  pushDataBatch,
} from "./api.js";
import { isSucceedStatusOps } from "./map.js";
import { createReportStatusHtml } from "@/utils/utils.js";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
import { mapHelper } from "@/utils/common.js";
export default {
  name: "reportPartyMemberInfo",
  components: {},
  props: {},
  data() {
    return {
      isLoading: false,
      isAllLoading: false,
      isShowAllBtn: true,
      submitConfig: {
        methods: "post",
        postData: {
          mapper: "OpsSzdzCommunityVotingInformationMapper",
          url: "szdz/outApi/communityVote/add",
          queryData: {
            tenantId: null,
            voteName: null,
            reportStatus: null,
          },
        },
      },
      isSucceedStatusOps: isSucceedStatusOps(),
      title: "编辑上报社区投票信息", //改动日志抽屉标题
      isEdit: true, //是否编辑
      isShowDrawer: false, //控制抽屉弹出
      isShowReportBtn: true, //显示上报按钮
      // filterHeaders: [], //表头数据
      searchParam: {
        // name: "",
        // community: "",
      },
      // 未来小区编码字段
      complexCode: {
        fieldLabel: "小区编码",
        fieldType: "select",
        fieldName: "complexCode",
        options: [],
        required: true,
      },
      // 未来社区编码字段
      futureCommunityCode: {
        fieldLabel: "未来社区编码",
        fieldType: "select",
        fieldName: "futureCommunityCode",
        options: [],
        required: true,
      },
      fieldList: [], //字段
      headers: [
        {
          prop: "voteName",
          label: "名称",
          show: true,
          align: "center",
        },
        {
          prop: "tenantId",
          label: "社区名称",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            return this.formatTenantId(row, prop);
          },
        },
        // {
        //   prop: "isReport",
        //   label: "是否上报",
        //   align: "center",
        //   formatter: (row, prop) => {
        //     let opts = {};
        //     if (Object.prototype.hasOwnProperty.call(row, "isReport")) {
        //       if (row[prop] === 1) {
        //         opts.txt = "已上报";
        //       } else if (row[prop] === 0) {
        //         opts.txt = "未上报";
        //       }
        //       return createReportStatusHtml(this, row, prop, opts);
        //     } else {
        //       return "--";
        //     }
        //   },
        //   show: true,
        // },
        {
          prop: "reportTime",
          label: "上报时间",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "reportStatus",
          label: "上报状态",
          align: "center",
          show: true,
          formatter: (row, prop) => {
            let opts = {};
            if (row[prop] !== "") {
              if (row[prop] === 1) {
                opts.txt = "已上报";
              } else if (row[prop] === 0) {
                opts.txt = "失败";
              }
            } else {
              opts.txt = "未上报";
            }
            return createReportStatusHtml(this, row, prop, opts);
          },
        },
        {
          prop: "updateTime",
          label: "修改日期",
          align: "center",
          show: true,
        },
        {
          prop: "",
          label: "操作",
          align: "center",
          key: "operation",
          show: true,
          width: 200,
        },
      ],
      tableUrl: getPageListUrl,
      dataList: {}, //详情数据
      reportData: {
        id: "",
        tenantId: "",
        url: "szdz/outApi/communityVote/add",
      },
      isAddData: true, //是否编辑or新增
      tenantList: [], //社区编码列表
    };
  },
  async created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    // this.filterHeaders = this.headers;
    await this.getComplexList();
    this.getFieldTypes();
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
    tenantName() {
      return this.tenantList.map((item) => {
        return { ...item, value: item.tenantId, label: item.tenantName };
      });
    },
  },
  mounted() {},
  methods: {
    afterList(arg) {
      this.isShowAllBtn = arg.length > 0 ? false : true;
      return arg;
    },
    selectable(row) {
      return row.responseResult == 0 ? true : false;
    },
    //全部上报or批量上报
    reportDataMode(id, type) {
      const params = {
        tenantId: this.accountInfo.tenantId,
        mapper: "OpsSzdzCommunityVotingInformationMapper",
        url: "szdz/outApi/communityVote/add",
      };
      let reportData, url;
      if (type == 1) {
        this.isLoading = true;
        const ids = id.ids.join(",");
        reportData = {
          ...params,
          ids,
        };
        url = getPushDataUrl;
      } else {
        this.isAllLoading = true;
        reportData = {
          ...params,
        };
        url = pushDataBatch;
      }
      this.reportCommonFun(reportData, url);
    },
    reportCommonFun(params, url) {
      this.$axios
        .post(`${url}`, params)
        .then((res) => {
          if (res.code == 200) {
            this.$refs.list.toggleSelection();
            this.$refs.list.getList();
            this.$message.success(res.msg);
          }
        })
        .finally(() => {
          this.$refs.list.toggleSelection();
          this.isLoading = false;
          this.isAllLoading = false;
        });
    },
    async beforeList() {
      await this.getTenantList();
    },
    handelChange() {
      this.$refs.list.search();
    },
    //格式社区名称
    formatTenantId(row, prop) {
      const { map: tenantNamedMap } = mapHelper.setMap(this.tenantName);
      return tenantNamedMap[row[prop]] ? tenantNamedMap[row[prop]] : "--";
    },
    //获取字段
    getFieldTypes() {
      const params = {
        className: "OpsSzdzCommunityVotingInformation",
      };
      this.$axios
        .post(getFieldTypes, this.$qs.stringify(params))
        .then((res) => {
          const { data } = res;
          this.fieldList = data;
          this.fieldList.unshift(this.futureCommunityCode, this.complexCode);
        });
    },
    //获取小区编码列表
    async getComplexList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getComplexList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.houseCode;
          item.label = item.name;
        });
        this.complexCode.options = data;
      }
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        this.submitConfig.postData.queryData.tenantId = data[0].tenantId;
        this.tenantList = data;
        data.forEach((item) => {
          item.value = item.futureCommunityCode;
          item.label = item.tenantName;
        });
        this.futureCommunityCode.options = data;
      }
    },
    //新增
    handelAddClick() {
      this.title = "新增上报社区投票信息";
      this.reportData.id = "";
      this.dataList = {};
      this.isShowDrawer = true;
      this.isEdit = true;
      this.isAddData = true;
      this.isShowReportBtn = false;
    },
    //重置
    reset() {
      this.submitConfig.postData.queryData.tenantId = null;
      this.submitConfig.postData.queryData.voteName = null;
      this.submitConfig.postData.queryData.reportStatus = null;
    },
    //查询
    goSearch() {
      this.$refs.list.getList();
    },
    // //更新表头数据
    // updateHeaders(arg) {
    //   this.filterHeaders = arg.filterHeaders;
    //   this.headers = arg.tableHeaders;
    // },

    //编辑抽屉弹窗显示
    changeDrawer(v) {
      this.isShowDrawer = v;
    },
    //获取详情
    getDataDetail(id) {
      const params = {
        id,
        mapper: "OpsSzdzCommunityVotingInformationMapper",
      };
      this.$axios.post(getDataInfo, params).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data;
          if (
            Object.prototype.hasOwnProperty.call(this.dataList, "complexCodes")
          ) {
            this.dataList.complexCodes = this.dataList.complexCodes.split(",");
          }
        } else {
          this.dataList = {};
        }
      });
    },
    //编辑数据
    async editData(row) {
      this.title = "编辑上报社区投票信息";
      this.reportData.id = row.id;
      this.reportData.tenantId = row.tenantId;
      // this.reportData.url = row.url ? row.url : "szdz/outApi/communityVote/add";
      this.isEdit = true;
      this.isAddData = false;
      this.isShowReportBtn = true;
      await this.getDataDetail(row.id);
      this.isShowDrawer = true;
    },
    //编辑or新增
    save(data) {
      if (!this.isAddData) {
        data.id = this.reportData.id;
      } else {
        const currentIndex = this.tenantList.findIndex(
          (item) => item.futureCommunityCode == data.futureCommunityCode
        );
        if (currentIndex != -1) {
          data.tenantId = this.tenantList[currentIndex].tenantId;
        } else {
          data.tenantId = this.accountInfo.tenantId;
        }
      }
      const params = {
        mapper: "OpsSzdzCommunityVotingInformationMapper",
        data,
      };
      const url = this.isAddData ? getAddData : editDataUrl;
      this.$axios.post(url, params).then((res) => {
        if (res.code == 200) {
          this.isShowDrawer = false;
          this.$refs.list.getList();
          this.$message({
            message: this.isAddData ? "新增成功" : "编辑成功",
            type: "success",
            duration: 500,
          });
        }
      });
    },
    //上报
    report(data) {
      const params = {
        ...this.reportData,
        mapper: "OpsSzdzCommunityVotingInformationMapper",
        data,
      };
      this.$axios.post(getPushDataUrl, params).then((res) => {
        if (res.code == 200) {
          this.isShowDrawer = false;
          this.$refs.list.getList();
          this.$message({
            message: "上报成功",
            type: "success",
            duration: 500,
          });
        }
      });
    },
    //查看数据
    async viewData(row) {
      this.title = "查看上报社区投票信息";
      this.isEdit = false;
      await this.getDataDetail(row.id);
      this.isShowDrawer = true;
    },
    //查看日志
    goViewLog(row) {
      this.$router.push({
        name: "baseDataViewLogReport",
        query: {
          id: row.id,
          url: "szdz/outApi/communityVote/add",
          tenantId: row.tenantId,
        },
      });
    },
    //导入
    goImportData() {
      if (!this.submitConfig.postData.queryData.tenantId) {
        return this.$message.error("请选择社区进行导入");
      }
      const curIndex = this.tenantName.findIndex(
        (item) => item.tenantId == this.submitConfig.postData.queryData.tenantId
      );
      const communityCode = this.tenantName[curIndex].futureCommunityCode;
      this.$router.push({
        name: "importCommunityVote",
        query: {
          futureCommunityCode: communityCode,
        },
      });
    },
    //删除
    deleteData(row) {
      if (row.responseResult == 1) {
        this.$confirm(
          "为保持数据同步，请确认省平台相关数据也已经删除！",
          "提示",
          {
            cancelButtonText: "确认删除",
            confirmButtonText: "省平台查看",
            distinguishCancelAndClose: true,
            type: "warning",
            cancelButtonClass: "cancelButtonClass",
          }
        )
          .then(() => {
            window.open("https://wlsq.jst.zj.gov.cn/sjgtpt/#/login");
          })
          .catch((action) => {
            if (action == "cancel") {
              this.deleteCommonFun(row);
            }
          });
      } else {
        this.$confirm("是否确认删除?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确认删除",
          type: "warning",
          confirmButtonClass: "cancelButtonClass",
        }).then(() => {
          this.deleteCommonFun(row);
        });
      }
    },
    deleteCommonFun(row) {
      const params = {
        id: row.id,
        mapper: "OpsSzdzCommunityVotingInformationMapper",
      };
      this.$axios.post(deleteDataUrl, params).then((res) => {
        if (res.code == 200) {
          this.$refs.list.getList();
          this.$message.success(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less">
.cancelButtonClass {
  background-color: #f37877 !important;
  color: #fff;
  border: none;
  &:hover {
    background-color: #f37877;
    color: #fff;
  }
}
</style>
<style scoped lang="less">
.reportPartyMemberInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 50px;
  ::v-deep .hc-list {
    flex: 1;
    .oTable {
      padding: 34px;
    }
    .search {
      background-color: #fff;
      box-sizing: border-box;
      padding: 20px 65px;
      margin-top: 10px;
      position: relative;
      .tag {
        cursor: pointer;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        position: absolute;
        left: -8px;
        top: 8px;
        padding: 5px;
        color: #fff;
        white-space: nowrap;
        background-color: #1f5ad8;
        border-radius: 2px;
        border-bottom-right-radius: 0;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          width: 0;
          height: 0;
          border-color: #bfbfbf transparent;
          border-width: 8px 0 0 8px;
          border-style: solid;
        }
      }
    }
  }
}

/deep/ .v-date-picker {
  margin: 0px;
}
</style>
